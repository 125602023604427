import React from 'react';
import Layout from 'layout';
import { ExtendGridBox } from 'pages/integrations/analytics';
import SideBar from 'components/marketplace/sideBar';
import Content from 'components/marketplace/content';
import Banner from 'components/marketplace/banner';
import { frontmatter } from 'data/marketplace/integrations';
import arrow from 'img/ui/arrow_black.svg';
import OwnAppSection from 'components/marketplace/ownAppSection';

const New = () => {
  const [mostPopularCard] = frontmatter.items.filter((item) => item.title === 'Sentry');
  return (
    <Layout
      metaTitle="New | Integrations"
      canonical="/integrations/new/"
      metaDescription="Have a look at the latest additions to our integration marketplace."
    >
      <div className="container">
        <ExtendGridBox>
          <div className="breadcrumbs">
            <p>
              <a href="/integrations/">Integrations</a>
            </p>
            <img src={arrow} alt="arrow" title="Arrow" />
            <p>
              <a href="/integrations/new/">
                <strong>New</strong>
              </a>
            </p>
          </div>
          <SideBar activeCategory="New" />
          <Content activeCategory="New" hideTitle>
            <Banner
              activeCategory="New"
              card={mostPopularCard}
              text="Have a look at the latest additions to our integration marketplace:"
            />
          </Content>
        </ExtendGridBox>
        <OwnAppSection />
      </div>
    </Layout>
  );
};

export default New;
